.popup-box {
    position: fixed;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 100000;
    padding: 20px;
    background: #ffffff;
    border-radius: 11px;
    box-shadow: 0px 9px 24px 0px rgba(0, 0, 0, 0.2);
}
