.info__main {
    .info {
        &__restaurant_name {
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 24px;
            text-transform: capitalize;
        }
        &__heading {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
        }
        &__sub_heading {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #595959;
            span {
                text-decoration: underline;
                cursor: pointer;
            }
        }
        &__divider {
            border-top: 1.5px solid rgba(0, 0, 0, 0.1) !important;
        }
    }
}
.info__main__dark {
    .info {
        &__restaurant_name {
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 24px;
            text-transform: capitalize;
        }
        &__heading {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
        }
        &__sub_heading {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #aeacac;
            span {
                text-decoration: underline;
                cursor: pointer;
            }
        }
        &__divider {
            border-top: 1.5px solid rgba(253, 253, 253, 0.1) !important;
        }
    }
}
