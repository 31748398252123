.comparison {
    min-height: 190vh;
    position: relative;
    &__help_icon {
        position: absolute;
        bottom: 2%;
        right: 0%;
        // width: 10%;
        @media (max-width: 992px) {
            right: 2%;
        }
    }
    &__Row {
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none !important;
        }
        &__input-field {
            background: #ffffff !important;
            border: 1px solid #dddde3 !important;
            border-radius: 4px !important;
            padding: 8px 8px 8px 16px !important;
            color: #27272a !important;
        }
        &__input-field-dark {
            background: rgba(134, 134, 134, 0.5);
            border: none !important;
            border-radius: 4px !important;
            padding: 8px 8px 8px 16px !important;
            color: #ffffff !important;
            &:focus {
                background: rgba(134, 134, 134, 0.5);
                color: #ffffff;
            }
        }
    }
    &__card {
        border: none;
        position: relative;
        background: transparent;
        &__img {
            border-radius: 10px;
        }
        .plus_icon {
            position: absolute;
            width: 23px;
            top: 2%;
            right: 3%;
        }
        &_body {
            .typography {
                color: #808089;
                line-height: 18px;
            }
            .title {
                color: #27272a;
                line-height: 21px;
                @media (max-width: 350px) {
                    font-size: 12px !important;
                }
                @media (max-width: 325px) {
                    font-size: 11px !important;
                }
            }
            .sub-title {
                color: #393f42;
                line-height: 18px;
                @media (max-width: 350px) {
                    font-size: 10px !important;
                }
                @media (max-width: 325px) {
                    font-size: 9px !important;
                }
            }
        }
        &_body-dark {
            .typography {
                color: #b3b3b3;
                line-height: 18px;
            }
            .title {
                color: #ffffff;
                line-height: 21px;
                @media (max-width: 350px) {
                    font-size: 12px !important;
                }
                @media (max-width: 325px) {
                    font-size: 11px !important;
                }
            }
            .sub-title {
                color: #ffffff;
                line-height: 18px;
                @media (max-width: 350px) {
                    font-size: 10px !important;
                }
                @media (max-width: 325px) {
                    font-size: 9px !important;
                }
            }
        }
    }
    &__call-to-action {
        color: #ffffff !important;
        border: none !important;
        border-radius: 4px !important;
    }
}
