.language-container-light {
    position: relative;
    .languages {
        background: #ffffff;
        border-radius: 8px;
        position: absolute;
        top: 0px;
        right: 0;
        z-index: 9999;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
        @media (max-width: 600px) {
            margin: 0 19vw;
            // width: 330px;
        }
        @media (max-width: 500px) {
            margin: 0 15vw;
        }
        @media (max-width: 400px) {
            margin: 0 9vw;
        }
        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #292929;
        }
        .buttons {
            background: rgba(134, 134, 134, 0.1) !important;
            border-radius: 8px !important;
            border: none;
            color: #3d3d3d !important;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.2px;
        }
    }

    .active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
.language-container-dark {
    position: relative;
    .languages {
        background: #525252;
        border-radius: 8px;
        position: absolute;
        top: 0px;
        right: 0;
        z-index: 9999;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
        @media (max-width: 600px) {
            margin: 0 19vw;
            // width: 330px;
        }
        @media (max-width: 500px) {
            margin: 0 15vw;
        }
        @media (max-width: 400px) {
            margin: 0 9vw;
        }
        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #ffffff;
        }
        .buttons {
            background: rgba(134, 134, 134, 0.1) !important;
            border-radius: 8px !important;
            border: none;
            color: #ffffff !important;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.2px;
        }
    }

    .active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
