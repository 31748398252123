.order_complete_modal {
    max-width: 360px !important;
    .title {
        color: #27ae60;
    }
}
.order_complete_modal-dark {
    max-width: 360px !important;
    .modal-content {
        background-color: #525252 !important;
    }
    .text-area {
        background: #3d3d3d !important;
        color: $heading-dark;
        border: none !important;
    }
    .title {
        color: #27ae60;
    }
}
