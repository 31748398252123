.slide-container {
    .slides__section {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin: 0px 3px;
            height: 400px;
            border-radius: 12px;
            width: 100% !important;
            background-size: cover;
            @media (max-width: 675px) {
                height: auto;
            }
        }
    }
    .prevArrow,
    .nextArrow {
        display: none;
    }
    .numeric-controls {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 16px;
        color: #fff;
        padding: 4px 7px;
        border-radius: 33%;
        background-color: black;
        z-index: 1;
        opacity: 0.6;
        span {
            margin-right: 10px;
        }
        .dot {
            display: inline-block;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin-right: 5px;
            background-color: #fff;
            opacity: 0.5;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            .active {
                opacity: 1;
                transform: scale(1.2);
            }
        }
    }
}
