.order_history {
    min-height: 81vh;
    &__img_div {
        width: 9%;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__content {
        @media (max-width: 470px) {
            padding: 0 !important;
        }
        .typograpghy {
            color: $heading;
            @media (max-width: 460px) {
                font-size: 10px !important;
            }
            @media (max-width: 400px) {
                font-size: 7px !important;
            }
        }
        .title {
            color: #27272a;
            @media (max-width: 460px) {
                font-size: 12px !important;
            }
            @media (max-width: 400px) {
                font-size: 10px !important;
            }
        }
        .price {
            @media (max-width: 400px) {
                font-size: 10px !important;
            }
        }
        .order_status {
            @media (max-width: 460px) {
                font-size: 12px !important;
            }
            @media (max-width: 400px) {
                font-size: 10px !important;
            }
        }
        .order_btn {
            border: none !important;
            border-radius: 16px;
            font-size: 10px !important;
            @media (max-width: 400px) {
                font-size: 8px !important;
            }
        }
    }
    &__card {
        background: #ffffff;
        border: 1px solid rgba(134, 134, 134, 0.24);
        border-radius: 8px;
        &:hover {
            border: 1px solid rgb(5, 159, 249);
        }
    }
    &__card-dark {
        background: #525252;
        border: 1px solid rgba(134, 134, 134, 0.24);
        border-radius: 8px;
        &:hover {
            border: 1px solid rgb(5, 159, 249);
        }
    }

    &__content-dark {
        @media (max-width: 470px) {
            padding: 0 !important;
        }
        .typograpghy {
            color: #b8b8b8;
            @media (max-width: 460px) {
                font-size: 10px !important;
            }
            @media (max-width: 400px) {
                font-size: 7px !important;
            }
        }
        .title {
            color: $heading-dark;
            @media (max-width: 460px) {
                font-size: 12px !important;
            }
            @media (max-width: 400px) {
                font-size: 10px !important;
            }
        }
        .price {
            @media (max-width: 400px) {
                font-size: 10px !important;
            }
        }
        .order_status {
            color: $heading-dark;
            @media (max-width: 460px) {
                font-size: 12px !important;
            }
            @media (max-width: 400px) {
                font-size: 10px !important;
            }
        }
        .order_btn {
            border: none !important;
            border-radius: 16px;
            font-size: 10px !important;
            @media (max-width: 400px) {
                font-size: 8px !important;
            }
        }
    }
}
