.restaurant__home {
    min-height: 81vh;
    .restaurant__profile {
        &__name {
            color: #000000;
        }
        &__img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            padding: 4px;
        }
        &__button {
            min-width: 35%;
            border: 0;
            border-radius: 50px;
            padding: 8px 0px !important;
        }
        &__divider {
            border-top: 1.5px solid rgba(0, 0, 0, 0.1) !important;
        }

        @media (max-width: 576px) {
            &__button {
                min-width: 60%;
            }
        }
    }
}
.restaurant__home__dark {
    min-height: 81vh;
    .restaurant__profile {
        &__name {
            color: #ffffff;
        }
        &__img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 1px solid #595959;
            padding: 4px;
        }
        &__button {
            min-width: 35%;
            border: 0;
            border-radius: 50px;
            padding: 8px 0px !important;
        }
        &__divider {
            border-top: 1.5px solid rgba(253, 253, 253, 0.1) !important;
        }

        @media (max-width: 576px) {
            &__button {
                min-width: 60%;
            }
        }
    }
}
