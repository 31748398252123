.footer-light {
    background-color: #ffffff;
    position: relative;
    @media only screen and (min-width: 2440.99px) {
        position: absolute;
        width: 100vw;
        bottom: 0;
    }
    .footer {
        &__top-row {
            flex-direction: column;
            // @media (max-width: 768px) {
            // }
        }
        &__left-col {
            display: flex;
            justify-content: center;
            align-items: center;
            // @media (max-width: 768px) {
            // }
            &__brand {
                img {
                    width: 50px;
                    height: 50px;
                }
                // cursor: pointer;
                // display: flex;
                // justify-content: start;
                // align-items: center;
                // gap: 15px;
                // h1 {
                //     color: #2d2e2e;
                // }
                // @media (max-width: 768px) {
                //     flex-direction: column;
                //     h1 {
                //         font-size: 18px !important;
                //     }
                // }
            }
        }
        &__right-col {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
            // @media (max-width: 768px) {
            //     justify-content: center;
            // }

            .social_icon {
                margin: 5px 8px;
                position: relative;
                width: 35px;
                height: 35px;
                border-radius: 100%;
                cursor: pointer;
            }
        }
        &__single-col {
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 768px) {
                justify-content: center;
                margin-top: 2rem;
            }

            .social_icon {
                margin: 5px 8px;
                position: relative;
                width: 35px;
                height: 35px;
                border-radius: 100%;
                cursor: pointer;
            }
        }
        &__bottom-text {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 2px;
            line-height: 24px;
            margin: 1rem 0;
            color: #2d2e2e;
            margin-bottom: 1.2rem !important;
            text-align: center;

            @media (max-width: 768px) {
                font-size: 12px;
                margin: 0;
            }
        }
        &__bottom-typography {
            color: #3d3d3d;
            text-align: center;
            letter-spacing: 0.4px;
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            cursor: pointer;
        }
        .copyright__row {
            flex-direction: column-reverse;
            justify-content: center !important;
            align-items: center !important;
            // @media (max-width: 767.99px) {
            //     display: flex;
            // }
        }
    }
}

.footer-dark {
    background-color: #303030;
    position: relative;
    @media only screen and (min-width: 2440.99px) {
        position: absolute;
        width: 100vw;
        bottom: 0;
    }
    .footer {
        &__top-row {
            flex-direction: column;
            // @media (max-width: 768px) {
            // }
        }
        &__left-col {
            display: flex;
            justify-content: center;
            align-items: center;
            // @media (max-width: 768px) {
            // }
            &__brand {
                img {
                    width: 50px;
                    height: 50px;
                }
                // cursor: pointer;
                // display: flex;
                // justify-content: start;
                // align-items: center;
                // gap: 15px;
                // h1 {
                //     color: #2d2e2e;
                // }
                // @media (max-width: 768px) {
                //     flex-direction: column;
                //     h1 {
                //         font-size: 18px !important;
                //     }
                // }
            }
        }
        &__right-col {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
            // @media (max-width: 768px) {
            // }

            .social_icon {
                margin: 5px 8px;
                position: relative;
                width: 35px;
                height: 35px;
                border-radius: 100%;
                cursor: pointer;
            }
        }
        &__single-col {
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 768px) {
                justify-content: center;
                margin-top: 2rem;
            }

            .social_icon {
                margin: 5px 8px;
                position: relative;
                width: 35px;
                height: 35px;
                border-radius: 100%;
                cursor: pointer;
            }
        }
        &__bottom-text {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 2px;
            line-height: 24px;
            margin: 1rem 0;
            color: #ffffff;
            margin-bottom: 1.2rem !important;
            text-align: center;

            @media (max-width: 768px) {
                font-size: 12px;
                margin: 0;
            }
        }
        &__bottom-typography {
            color: #ffffff;
            text-align: center;
            letter-spacing: 0.4px;
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            cursor: pointer;
        }
        .copyright__row {
            flex-direction: column-reverse;
            justify-content: center !important;
            align-items: center !important;
            // @media (max-width: 767.99px) {
            //     display: flex;
            // }
        }
    }
}
