.top_cards {
    padding: 8px;
    border: none;
    background: transparent;

    &__title {
        color: #27272a;
        line-height: 24px;
    }
    &__subtitle {
        color: #27272a;
        line-height: 21px;
    }

    .react-slideshow-container {
        .default-nav {
            margin: 0 1rem !important;
        }
    }

    &__textarea {
        width: 97% !important;
    }
    &__sizes {
        border-bottom: 1px solid #ebebf0;
        .radio {
            margin-top: 0.5rem;
        }
    }
    &__operators {
        border: 2px solid #ebebf0;
        border-radius: 100%;
        height: 50px;
        width: 50px;
        margin: 0 6px;
        cursor: pointer;
        @media (max-width: 330px) {
            margin: 0 1px;
        }
        &-active {
            border-radius: 100%;
            height: 50px;
            width: 50px;
            margin: 0 6px;
            cursor: pointer;
            @media (max-width: 330px) {
                margin: 0 1px;
            }
        }
    }
    &__number {
        border: 2px solid #ebebf0;
        border-radius: 12%;
        padding: 4px 12px;
        font-size: 23px;
        margin: 0 12px;
    }
    &__addtocart {
        border: none !important;
        padding: 11px 66px !important;
        border-radius: 27px !important;
    }
}
.search-box {
    border: 1px solid rgba(134, 134, 134, 0.24) !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-radius: 10px !important;
    padding: 5px !important;
    margin: 10px 0 !important;

    .form-control {
        border: none !important;
    }
}

.top_cards-dark {
    border-radius: 12px;
    padding: 8px;
    background: transparent !important;
    border: none !important;

    &__title {
        color: #ffffff;
        line-height: 24px;
    }
    &__subtitle {
        color: #ffffff;
        line-height: 21px;
    }

    .react-slideshow-container {
        .default-nav {
            // display: none !important;
            margin: 0 1rem !important;
        }
    }

    &__textarea {
        width: 300px !important;
        @media (max-width: 400px) {
            width: 97% !important;
        }
        .form-control {
            background: #3d3d3d !important;
            color: #ffffff !important;
        }
    }
    &__sizes {
        border-bottom: 1px solid #ebebf0;
        .radio {
            margin-top: 0.5rem;
        }
    }
    &__operators {
        border: 2px solid #ebebf0;
        border-radius: 100%;
        height: 50px;
        width: 50px;
        margin: 0 6px;
        color: #ffffff;
        cursor: pointer;
        @media (max-width: 330px) {
            margin: 0 1px;
        }
        &-active {
            border-radius: 100%;
            height: 50px;
            width: 50px;
            margin: 0 6px;
            cursor: pointer;
            @media (max-width: 330px) {
                margin: 0 1px;
            }
        }
    }
    &__number {
        border: 2px solid #ebebf0;
        border-radius: 12%;
        padding: 4px 12px;
        font-size: 23px;
        margin: 0 12px;
        color: #ffffff;
    }
}
.search-box-dark {
    border: 1px solid rgba(134, 134, 134, 0.24) !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-radius: 10px !important;
    padding: 5px !important;
    margin: 10px 0 !important;

    .form-control {
        border: none !important;
        background: transparent !important;
        color: #ffffff !important;
    }
}
