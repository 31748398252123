.marquee-container {
    overflow: hidden;
    white-space: nowrap;
}

.marquee-content {
    animation: marquee 15s linear infinite;
}
.marquee-container:hover .marquee-content {
    animation-play-state: paused;
}

@keyframes marquee {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(-100vw);
    }
}

// language translator google-translate
.goog-te-gadget img {
    display: none !important;
}
body > .skiptranslate {
    display: none;
}
body {
    top: 0px !important;
}

#google_translate_element {
    span {
        display: none !important;
    }
}
.goog-logo-link {
    display: none !important;
}

.goog-te-gadget {
    color: transparent !important;
}

.goog-te-gadget-icon {
    display: none;
}
.goog-te-combo {
    // position: absolute;
    // right: 4%;
    padding: 4px;
    // top: 14px;
    border: 1px solid lightgray;
    border-radius: 6px;
    margin: 0px !important;
    // @media (max-width: 2100px) {
    //     right: 5%;
    // }
    // @media (max-width: 1800px) {
    //     right: 6%;
    // }
    // @media (max-width: 1600px) {
    //     right: 7%;
    // }
    // @media (max-width: 1440px) {
    //     right: 9%;
    // }
    // @media (max-width: 1024px) {
    //     right: 10%;
    // }
    // @media (max-width: 910px) {
    //     right: 12%;
    // }
    // @media (max-width: 768px) {
    //     right: 14%;
    // }
    // @media (max-width: 698px) {
    //     right: 15%;
    // }
    // @media (max-width: 648px) {
    //     right: 17%;
    // }
    // @media (max-width: 558px) {
    //     right: 19%;
    // }
    // @media (max-width: 508px) {
    //     right: 22%;
    // }
    // @media (max-width: 445px) {
    //     right: 24%;
    // }
    // @media (max-width: 395px) {
    //     right: 28%;
    // }
    // @media (max-width: 340px) {
    //     right: 30%;
    // }
}
.VIpgJd-ZVi9od-aZ2wEe-OiiCO-ti6hGc {
    display: none !important;
}
.VIpgJd-yAWNEb-L7lbkb {
    display: none !important;
}
