.payment_method {
    min-height: 81vh;
    &__card {
        border: 1px solid rgba(134, 134, 134, 0.24);
        border-radius: 8px;
        &_row {
            .card_image {
                @media (max-width: 395px) {
                    width: 53px;
                }
            }
            .trash_icon {
                margin-bottom: 1px;
                @media (max-width: 395px) {
                    width: 10px;
                }
            }
            .card_status {
                font-weight: 600;
                font-size: 12px;
                line-height: 29px;
                text-align: center;
                @media (max-width: 395px) {
                    font-size: 10px;
                }
                @media (max-width: 360px) {
                    font-size: 8px;
                }
                @media (max-width: 330px) {
                    font-size: 7px;
                }
            }
            .typograhy {
                font-family: "Poppins", sans-serif;
                font-size: 17px;
                line-height: 29px;
                text-align: center;
                color: #525252;
                @media (max-width: 395px) {
                    font-size: 14px;
                }
                @media (max-width: 360px) {
                    font-size: 12px;
                }
                @media (max-width: 330px) {
                    font-size: 10px;
                }
            }
            .radio_box {
                bottom: 38%;
            }
        }
    }
    &__card-dark {
        background: #525252;
        border: 1px solid rgba(134, 134, 134, 0.24);
        border-radius: 8px;
        &_row {
            .card_image {
                @media (max-width: 395px) {
                    width: 53px;
                }
            }
            .trash_icon {
                margin-bottom: 1px;
                @media (max-width: 395px) {
                    width: 10px;
                }
            }
            .card_status {
                font-weight: 600;
                font-size: 12px;
                line-height: 29px;
                text-align: center;
                @media (max-width: 395px) {
                    font-size: 10px;
                }
                @media (max-width: 360px) {
                    font-size: 8px;
                }
                @media (max-width: 330px) {
                    font-size: 7px;
                }
            }
            .typograhy {
                font-family: "Poppins", sans-serif;
                font-size: 17px;
                line-height: 29px;
                text-align: center;
                color: #ffffff;
                @media (max-width: 395px) {
                    font-size: 14px;
                }
                @media (max-width: 360px) {
                    font-size: 12px;
                }
                @media (max-width: 330px) {
                    font-size: 10px;
                }
            }
            .radio_box {
                bottom: 38%;
            }
        }
    }
}
