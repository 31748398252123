.table {
    position: relative;
    &__check-box {
        padding: 0.8rem 2rem;
        border-radius: 8px;
        border: 1px solid green;
        margin-bottom: 1rem;
        label {
            margin-left: 5px;
            font-size: 14px;
            font-weight: 500;
        }
    }
    &__navigation {
        position: absolute;
        top: 2%;
    }
    &__icons {
        width: 20px;
    }
    &__bottom {
        // min-height: 90vh;
    }
    &__overlay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(239, 43, 85, 0.05);
        // min-height: 80vh;
        padding: 64px 0;
    }
    &__overlay-dark {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // min-height: 80vh;
        background: transparent;
        padding: 64px 0;
    }
    &__frame {
        width: 50%;
    }
    &__call_btn {
        border: none !important;
        padding: 11px 66px !important;
        border-radius: 27px !important;
        @media (max-width: 525px) {
            width: 100%;
        }
    }
    &__help_support {
        cursor: point;
    }
    &__search-box {
        border: 1px solid rgba(134, 134, 134, 0.24) !important;
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        border-radius: 10px !important;
        padding: 5px !important;
        // margin: 10px 0 !important;

        .form-control {
            border: none !important;
            color: $heading;
        }
    }
    &__search-box-dark {
        border: 1px solid rgba(221, 221, 227, 0.4) !important;
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        border-radius: 10px !important;
        padding: 5px !important;
        // margin: 10px 0 !important;

        .form-control {
            border: none !important;
            background: transparent !important;
            color: $heading-dark;
        }
    }
}
