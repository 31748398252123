/* CSS Styles */
.dropbtn {
  background-color: #dc3545;
  color: white;
  padding: 8px;
  font-size: 14px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  border-radius: 12px;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  border-radius: 12px;
  display: block;
}

.dropdown-content li:hover {
  background-color: #ddd;
  border-radius: 12px;
}

.show {
  display: block !important;
}
