.categories {
    &__menu {
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none !important;
        }
    }
    .food_text {
        color: #939393;
        font-size: 14px;
        font-weight: 500;
        line-height: 14.52px;
    }
    .food_text-active {
        color: #d70365;
    }
    .food_text-dark {
        color: #c0c0c0;
        font-size: 14px;
        font-weight: 500;
        line-height: 14.52px;
    }
    .menu_section {
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .menu_section-active {
        border-bottom: 2px solid #d70365;
    }
    &__button {
        background-color: #ffffff !important ;
        border: none !important;
        color: #344a53 !important;
        border-radius: 30px !important;
        padding: 3px 8px !important;
        font-size: 14px;
        min-width: 90px;
        box-shadow: 3px 8px 15px rgba(0, 0, 0, 0.1);
        @media (max-width: 350px) {
            font-size: 12px !important;
        }
        &_active {
            border: none !important;
            color: #ffffff !important;
            border-radius: 30px !important;
            padding: 3px 8px !important;
            font-size: 14px;
            min-width: 90px;
            @media (max-width: 350px) {
                font-size: 12px !important;
            }
        }
    }
    &__button-dark {
        background-color: #484b4c !important ;
        border: none !important;
        color: #c0c0c0 !important;
        border-radius: 30px !important;
        padding: 3px 8px !important;
        font-size: 14px;
        min-width: 90px;
        @media (max-width: 350px) {
            font-size: 6px !important;
        }
        &_active {
            border: none !important;
            color: #ffffff !important;
            border-radius: 30px !important;
            padding: 3px 8px !important;
            min-width: 90px;
            font-size: 14px;
            @media (max-width: 350px) {
                font-size: 6px !important;
            }
        }
    }
    &__card {
        img {
            width: 100%;
        }
    }
    &__card-2 {
        background: #f55505d9;
        padding: 22px 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        border-radius: 15px;
    }
}
