.most_popular {
    position: relative;
    &__view-all {
        text-decoration: underline;
        cursor: pointer;
    }
    &__card {
        .card {
            width: 100% !important;
            border: none !important;
            background: transparent;
            .title {
                @media (max-width: 390px) {
                    font-size: 12px !important;
                }
            }
            .typography {
                @media (max-width: 390px) {
                    font-size: 10px !important;
                }
            }
            .star_img {
                @media (max-width: 390px) {
                    width: 10px;
                    height: 10px;
                }
            }
            .product_profile {
                border-radius: 5px 5px 0 0 !important;
                margin-bottom: 0.5px !important;
                max-width: 230px;
                max-height: 150px;
            }
            .plus_icon {
                position: absolute;
                top: 3%;
                right: 4%;
                width: 20px;
            }
        }
        @media (max-width: 440px) {
            padding: 0 !important;
        }
    }
    &__card-dark {
        .card {
            background: transparent !important;
            border: none !important;
            color: #ffffff !important;
            width: 100%;
            .title {
                @media (max-width: 390px) {
                    font-size: 12px !important;
                }
            }
            .typography {
                @media (max-width: 390px) {
                    font-size: 10px !important;
                }
            }
            .star_img {
                @media (max-width: 390px) {
                    width: 10px;
                    height: 10px;
                }
            }
            .product_profile {
                border-radius: 5px 5px 0 0 !important;
                margin-bottom: 0.5px !important;
                max-width: 230px;
                max-height: 150px;
            }
            .plus_icon {
                position: absolute;
                top: 3%;
                right: 4%;
                width: 20px;
            }
        }

        @media (max-width: 440px) {
            padding: 0 !important;
        }
    }
}
