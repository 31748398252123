.Header {
    &__logo {
        width: 25px;
        text-align: center;
    }
    .navbar {
        padding: 1rem 1rem !important;
        &__items {
            @media (max-width: 340px) {
                margin: 0 !important;
            }
        }
        .navbar-toggler {
            border: 0 !important;
            @media (max-width: 340px) {
                padding: 0 !important;
            }
        }
        .navbar-toggler-icon {
            background-image: url("../img/hamburgerLight.svg") !important;
        }
    }

    .sidebar {
        position: fixed;
        top: 0;
        left: -285px;
        width: 285px;
        height: 100vh;
        background: $body-bg;
        border-radius: 0px 27px 0 0;
        transition: left 0.3s ease;
        z-index: 99999;
        @media (max-width: 450px) {
            width: 270px;
        }
        @media (max-width: 330px) {
            width: 262px;
        }

        &__top-row {
            position: fixed;
            border-radius: 0px 27px 0 0;
            width: 285px;
            z-index: 1;
            @media (max-width: 450px) {
                width: 270px;
            }
            @media (max-width: 330px) {
                width: 262px;
            }
            &_profile {
                padding: 25px 21px;
                color: #ffffff;

                height: "100px";
            }
        }
        &__cancel {
            cursor: pointer;
            margin: 4px 8px;
            position: absolute;
            top: 6%;
            left: 87%;
            z-index: 99999;
            svg {
                width: 20px;
                height: 20px;
            }
        }
        &__menu {
            position: absolute;
            top: 26%;
            width: 285px;
            @media (max-width: 450px) {
                width: 270px;
            }
            @media (max-width: 330px) {
                width: 262px;
            }

            &__navigation {
                cursor: pointer;
            }
        }
        &__button {
            color: #ffffff !important;
            border: none !important;
            border-radius: 7px !important;
            padding: 0 17px;

            &_feedback {
                background-color: #0098d6 !important;
                color: #ffffff !important;
                border: none !important;
                border-radius: 7px !important;
                padding: 0 17px;
            }
        }
    }
    .open {
        left: 0;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none !important;
        }
    }
}
.Header-dark {
    &__logo {
        width: 25px;
        text-align: center;
    }
    .navbar {
        padding: 1rem 1rem !important;
        &__items {
            @media (max-width: 340px) {
                margin: 0 !important;
            }
        }
        .navbar-toggler {
            border: 0 !important;
            @media (max-width: 340px) {
                padding: 0 !important;
            }
        }
        .navbar-toggler-icon {
            background-image: url("../img/hamburgerDark.svg") !important;
        }
    }
    .sidebar {
        position: fixed;
        top: 0;
        left: -285px;
        width: 285px;
        height: 100vh;
        background: #3d3d3d;
        border-radius: 0px 27px 0 0;
        transition: left 0.3s ease;
        z-index: 99999;
        @media (max-width: 450px) {
            width: 270px;
        }
        @media (max-width: 330px) {
            width: 262px;
        }
        &__top-row {
            position: fixed;
            border-radius: 0px 27px 0 0;
            width: 285px;
            z-index: 1;
            @media (max-width: 450px) {
                width: 270px;
            }
            @media (max-width: 330px) {
                width: 262px;
            }

            &_profile {
                padding: 25px 21px;
                color: #ffffff;
            }
        }
        &__cancel {
            cursor: pointer;
            // font-size: 1rem;
            // font-weight: 600;
            margin: 4px 8px;
            position: absolute;
            top: 6%;
            left: 87%;
            z-index: 99999;
            svg {
                width: 20px;
                height: 20px;
            }
        }
        &__menu {
            position: absolute;
            top: 26%;
            width: 285px;
            @media (max-width: 450px) {
                width: 270px;
            }
            @media (max-width: 330px) {
                width: 262px;
            }

            &__navigation {
                cursor: pointer;
                p {
                    color: #ffffff;
                }
            }
        }
        &__button {
            color: #ffffff !important;
            border: none !important;
            border-radius: 7px !important;
            padding: 0 17px;

            &_feedback {
                background-color: #0098d6 !important;
                color: #ffffff !important;
                border: none !important;
                border-radius: 7px !important;
                padding: 0 17px;
            }
        }
    }
    .open {
        left: 0;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none !important;
        }
    }
}
