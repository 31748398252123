.cart {
    min-height: 54vh;
    &__shipping {
        border-bottom: 1px solid #c0c0c4;
        &_pricing {
            .price_heading {
                font-weight: 400;
                font-size: 14px;
                text-transform: capitalize;
                color: #28282b;
            }
        }
    }

    &__payment_card {
        background: #ffffff !important;
        width: 50%;
        border-radius: 8px !important;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    &__payment_card-dark {
        width: 50%;
        border-radius: 8px !important;
        background: #3d3d3d !important;
        color: #ffffff !important;
        border: 1px solid #95989a !important;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    &__payment_card-payment {
        background: #ffffff !important;
        width: 50%;
        border-radius: 8px !important;
        padding: 1rem 0;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    &__payment_card-payment-dark {
        width: 50%;
        border-radius: 8px !important;
        background: #3d3d3d !important;
        padding: 1rem 0;
        color: #ffffff !important;
        border: none !important;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .tip {
        &_row {
            background: #f3f3f3;
            border-radius: 8px;
        }
        &_button {
            background: transparent !important;
            font-size: 16px !important;
            font-weight: 600 !important;
            border: none !important;
            color: black !important;
            padding: 0.5rem 1rem !important;
        }
        &_button-active {
            font-size: 16px !important;
            font-weight: 600 !important;
            border: none !important;
            color: $heading-dark !important;
            padding: 0.5rem 1rem !important;
        }
        &__amount {
            .form-control {
                background: transparent !important;
                border-radius: 8px !important;
                padding: 20px 2rem !important;
                color: black !important;
            }
        }
        &__amount-dark {
            .form-control {
                background: transparent !important;
                border-radius: 8px !important;
                padding: 20px 2rem !important;
                color: $heading-dark !important;
            }
        }
    }

    &__contact_btn {
        border: none !important;
        border-radius: 16px !important;
    }
    .lock-bg {
        background: white;
        border-radius: 59%;
    }
    .vertical-stepper {
        padding-bottom: 20px;

        .step {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            position: relative;
            padding-top: 20px;
            font-size: 14px;
            color: #95989a;
        }

        .step-inner {
            display: flex;
            justify-content: center;
            padding: 0px 10px;
        }

        .step-inner-circle {
            display: block;
            height: 8px;
            width: 8px;
            background: #cccccc;
            border-radius: 50%;
            z-index: 2;
        }

        .step-inner-line {
            height: 100%;
            position: absolute;
            width: 2px;
            background: #cccccc;
            z-index: 1;
        }

        .last-step__completed > .step-right-content {
            font-weight: bold;
        }

        .step:last-child > .step-inner .step-inner-line {
            display: none;
        }
    }
}
