.recommended_items {
    .recommended_card {
        background-color: #ffffff;
        margin-bottom: 0.7rem;
        &__img {
            // height: 85px;
            // width: 112px;
            border-radius: 4px;
            width: 6rem;
            @media (max-width: 430px) {
                width: 100px;
                height: 100px;
            }
        }
        &__body {
            .title {
                color: #27272a;
                font-weight: 500;
                font-size: 14px;
                @media (max-width: 410px) {
                    font-size: 13px;
                }
            }
            .title-dark {
                color: #ffffff;
                font-weight: 500;
                font-size: 14px;
                @media (max-width: 410px) {
                    font-size: 13px;
                }
            }
            .sub_title {
                font-size: 11px;
                font-weight: 400;
                color: #808089;
                @media (max-width: 410px) {
                    font-size: 10px;
                }
            }
            .sub_title-dark {
                font-size: 11px;
                font-weight: 400;
                color: #ffffff;
                @media (max-width: 410px) {
                    font-size: 10px;
                }
            }
            .price {
                font-size: 12px;
                color: red;
                font-weight: 400;
                @media (max-width: 410px) {
                    font-size: 11px;
                }
            }
        }
    }
}
