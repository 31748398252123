.auth {
    min-height: 70vh;
    display: flex;
    align-items: center;
    .image_upload {
        position: absolute;
        bottom: 1%;
        right: -3%;
        width: 23px;
        cursor: pointer;
        // @media (max-width: 1024px) {
        //     top: 72%;
        // }
        // @media (max-width: 1024px) {
        //     top: 70%;
        // }
        // @media (max-width: 768px) {
        //     top: 74%;
        // }
        // @media (max-width: 630px) {
        //     top: 70%;
        // }
        // @media (max-width: 525px) {
        //     top: 68%;
        //     width: 20px;
        // }
        // @media (max-width: 390px) {
        //     top: 64%;
        //     width: 18px;
        // }
    }
    &__card {
        background: transparent;
        border: none;
        width: 60% !important;

        @media (max-width: 768px) {
            width: 85% !important;
        }
        @media (max-width: 425px) {
            width: 100% !important;
        }
    }
    &__card-dark {
        width: 60% !important;
        background: transparent !important;
        border: none !important;
        border-radius: 8px !important;

        @media (max-width: 768px) {
            width: 85% !important;
        }
        @media (max-width: 425px) {
            width: 100% !important;
        }
    }
    &__btn {
        border: none !important;
        // border-radius: 15px !important;
    }
}
